import React, { useState } from "react";
import Grid from "app/components/Grid";
import { Waypoint } from "app/components/Waypoint";
import { UserVideoModal } from "app/components/UserVideos/UserVideoModal";
import { VideoEncodingStatus } from "services/graphql";
import { UserVideoItem } from "./UserVideoItem";

interface Props {
  videos: any[];
  fetchMore: () => void;
  hasMore: boolean;
  fromModule: string;
}

export const UserVideoList = ({
  videos = [],
  fetchMore = () => {},
  hasMore,
  fromModule,
}: Props) => {
  const [userVideoId, setUserVideoId] = useState(null);
  const openModal = ({
    id,
    encodingStatus,
  }: {
    id: string;
    encodingStatus: VideoEncodingStatus;
  }) => {
    if (encodingStatus !== VideoEncodingStatus.Available) {
      return;
    }
    setUserVideoId(id);
  };

  return (
    <>
      <Grid
        position="relative"
        width="100%"
        justifyContent="center"
        gridGap="6px"
        gridTemplateColumns={{
          _: "repeat(1, minMax(304px, 1fr))",
          xs: "repeat(1, minMax(343px, 1fr))",
          md: "repeat(3, minMax(248px, 1fr))",
        }}
      >
        {videos.map(({ node }) => (
          <UserVideoItem
            key={node.id}
            submission={node}
            openModal={openModal}
          />
        ))}
      </Grid>
      <Waypoint fetchData={fetchMore} hasMore={hasMore} />
      {!!userVideoId && (
        <UserVideoModal
          userVideoId={userVideoId}
          closeModal={() => setUserVideoId(null)}
          fromModule={fromModule}
        />
      )}
    </>
  );
};
