import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { ANALYTICS_MODULE } from "constants/index";
import { UserVideoList } from "./UserVideoList";
import UserVideosEmptyState from "./UserVideosEmptyState";

const UploadedVideoGrid = ({ videos = [], fetchMore = () => {}, hasMore }) => {
  return isEmpty(videos) ? (
    <UserVideosEmptyState />
  ) : (
    <UserVideoList
      videos={videos}
      fetchMore={fetchMore}
      hasMore={hasMore}
      fromModule={ANALYTICS_MODULE.my_profile}
    />
  );
};

UploadedVideoGrid.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
};

export default UploadedVideoGrid;
