import styled from "styled-components";
import Flex from "app/components/Flex";

const Wrapper = styled(Flex)``;

const ProfilePhotoWrapper = styled(Flex)`
  border-radius: 50%;
  overflow: hidden;
`;

export { Wrapper, ProfilePhotoWrapper };
