import React from "react";
import Flex from "app/components/Flex";
import Thumbnail from "app/components/Thumbnail";
import ReportOverlay from "app/components/Challenges/ChallengesComponents/ReportOverlay";
import VideoCameraBackground from "app/assets/svg/video-camera-background.svg";
import { P1 } from "app/components/Typography";
import Text from "app/components/Text";
import env from "helpers/env";
import { VideoEncodingStatus } from "services/graphql";
import { OverflowText } from "./styles";
import { VideoEncodingState } from "./VideoEncodingState";

interface Props {
  key: string;
  submission: any;
  openModal: (submission: any) => void;
}

export const UserVideoItem = ({ key, submission, openModal }: Props) => {
  const {
    description,
    thumbnailUrl,
    encodingStatus,
    reportedByMe,
  } = submission;
  const onClick = () => openModal(submission);
  const isEncoding = encodingStatus !== VideoEncodingStatus.Available;

  if (isEncoding) {
    return <VideoEncodingState key={key} />;
  }

  return (
    <Flex justifyContent="center" onClick={onClick} key={key} cursor="pointer">
      <Flex flexDirection="column">
        <Flex position="relative" flexDirection="column">
          <Thumbnail
            src={thumbnailUrl}
            placeholderSrc={`${env("PUBLIC_ASSET_PATH")}/challenges/black.jpg`}
            width={{ _: "356px", md: "248px" }}
            mb="12px"
            borderRadius="5px"
          />
          {reportedByMe && (
            <ReportOverlay iconWidth="40px" iconHeight="40px">
              <P1 color="white" mt={3}>
                Post Reported
              </P1>
            </ReportOverlay>
          )}
        </Flex>
        <Flex
          height="40px"
          maxHeight="40px"
          maxWidth={{ _: "356px", md: "248px" }}
          mb={3}
        >
          <OverflowText>{description}</OverflowText>
        </Flex>
      </Flex>
    </Flex>
  );
};
