import styled from "styled-components";
import { P1 } from "app/components/Typography";

const OverflowText = styled(P1)`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding: 0 5px;
`;

export { OverflowText };
