import React, { useRef } from "react";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import Flex from "app/components/Flex";
import Avatar from "app/components/Avatar";
import { H2 } from "app/components/Typography";
import { ScreenGtSm, ScreenLtSm } from "app/components/MediaQueries";
import { useGetUserProfile } from "hooks/User";
import ProfileMetrics from "./ProfileMetrics";
import UploadedVideoGrid from "./UploadedVideoGrid";
import { Wrapper, ProfilePhotoWrapper } from "./styles";

const Profile = () => {
  const wrapperRef = useRef();

  const [profileData, loading, , fetchMore] = useGetUserProfile();

  if (loading) {
    return <LoaderCentered />;
  }

  const {
    uploadedVideos: { edges = [], totalCount: uploadedVideosCount } = {},
    username,
    photoURL,
    totalLikesCount,
  } = profileData;

  const hasMore = edges.length < uploadedVideosCount;

  return (
    <Wrapper
      width="100%"
      flexDirection="column"
      alignItems="center"
      ref={wrapperRef}
      px={3}
    >
      <Flex
        flexDirection="column"
        mt={{ _: 3, sm: 5 }}
        maxWidth="756px"
        alignItems="center"
      >
        <Flex flexDirection="column" width="100%">
          <ScreenLtSm>
            <H2 mb={3} fontSize="18px">
              @{`${username}`}
            </H2>
          </ScreenLtSm>
          <Flex width="100%" mb="48px">
            <ProfilePhotoWrapper
              justifyContent="center"
              height={{ _: "64px", md: "140px" }}
              width={{ _: "64px", md: "140px" }}
              mr="24px"
            >
              <Avatar src={photoURL} />
            </ProfilePhotoWrapper>
            <Flex
              flexDirection="column"
              justifyContent="center"
              maxWidth="600px"
            >
              <ScreenGtSm>
                <H2 mb={3}>@{`${username}`}</H2>
              </ScreenGtSm>
              <ProfileMetrics
                postsCount={uploadedVideosCount}
                likesCount={totalLikesCount}
              />
            </Flex>
          </Flex>
          <UploadedVideoGrid
            videos={edges}
            fetchMore={fetchMore}
            hasMore={hasMore}
          />
        </Flex>
      </Flex>
    </Wrapper>
  );
};

export default Profile;
