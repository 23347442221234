import React from "react";
import Flex from "app/components/Flex";
import VideoCameraBackground from "app/assets/svg/video-camera-background.svg";
import Text from "app/components/Text";

export const VideoEncodingState = ({ key }: { key: string }) => {
  return (
    <Flex justifyContent="center" key={key} cursor="default">
      <Flex flexDirection="column">
        <Flex position="relative" flexDirection="column">
          <Flex
            as={VideoCameraBackground}
            width={{ _: "356px", md: "248px" }}
            mb="12px"
            borderRadius="5px"
          />
          <Text
            position="absolute"
            color="white"
            fontSize={{ _: "20px", md: "14px" }}
            maxWidth="70%"
            top="43%"
            left="15%"
            textAlign="center"
          >
            Your video is still processing. Check back in a few minutes!
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
